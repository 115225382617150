import React, { useState, useEffect } from "react";
import { getRequest, patchRequest, postRequest } from "../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Hidden,
  ListItemText,
  Checkbox,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const AddRole = ({ open, handleClose, roleId }) => {
  const rolePermissions = [
    "addAllowances",
    "addFitnessAllowances",
    "addLeaves",
    "addMedicalAllowances",
    "applyLeaves",
    "deleteEmployees",
    "deleteFitness",
    "deleteMedical",
    "deleteLeaves",
    "getOwnEmployee",
    "getEmployees",
    "getFitnessAllowances",
    "getLeaves",
    "getLeave",
    "getMedicalAllowances",
    "getSalary",
    "getProject",
    "manageAdmins",
    "manageClients",
    "manageDepartments",
    "manageEmployees",
    "manageFitnessAllowances",
    "manageInventory",
    "manageInvoices",
    "manageMedicalAllowances",
    "manageProjects",
    "manageRoles",
    "manageSalary",
    "manageLeaves",
    "updateEmployees",
    "updateFitness",
    "updateMedical",
    "updateLeave",
  ];
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState({
    name: "",
    permissions: [],
  });

  useEffect(() => {
    const fetchRole = async () => {
      if (roleId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const res = await getRequest(`admin/role/${roleId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          setRoleData({
            name: res.name,
            permissions: res.permissions,
          });
        } catch (error) {
          console.error("Error fetching Role:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setRoleData({
          name: "",
          permissions: [],
        });
      }
    };
    fetchRole();
  }, [roleId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `admin/role`,
        {
          Authorization: `Bearer ${access}`,
        },
        roleData
      );
      toast.success("Role Updated Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "admin/role",
        {
          Authorization: `Bearer ${access}`,
        },
        roleData
      );
      toast.success("Role Added Successfully", toastConfig);
      setRoleData({
        name: "",
        permissions: [],
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          {roleId !== null ? "Update Role Record" : "Add Role Record"}
        </Typography>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item md={4}>
              <ManageAccountsIcon
                style={{
                  width: "100%",
                  height: "90%",
                  borderRadius: "8px",
                  color: "#E5622D",
                }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-name">
                    Role Name
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-name"
                    type="text"
                    name="name"
                    label="Role Name"
                    value={roleData.name}
                    onChange={handleChange}
                    inputProps={{ readOnly: !!roleId }}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-permissions">
                    Role Permissions
                  </InputLabel>
                  <Select
                    labelId="outlined-adornment-permissions-label"
                    id="outlined-adornment-permissions"
                    name="permissions"
                    label="Role Permissions"
                    multiple
                    value={roleData.permissions}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(", ")}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    {rolePermissions.map((permissionOption) => (
                      <MenuItem key={permissionOption} value={permissionOption}>
                        <Checkbox
                          checked={
                            roleData.permissions.indexOf(permissionOption) > -1
                          }
                        />
                        <ListItemText primary={permissionOption} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-around">
                {roleId !== null ? (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleUpdate}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSubmit}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleClose}
                    disabled={loading}
                    sx={{
                      bgcolor: "#A9A9A9",
                      "&:hover": { bgcolor: "#E1E1E1" },
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddRole;
