import React, { useState, useEffect } from "react";
import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../../axiosRoutes/toastConfig";

const AddLeave = ({ open, handleClose, leaveId }) => {
  const [loading, setLoading] = useState(false);
  const [leaveType, setLeaveType] = useState([]);
  const [leaveData, setLeaveData] = useState({
    startDate: "",
    type: "",
    endDate: "",
    reason: "",
  });

  const fetchLeaveType = async () => {
    try {
      const response = await getRequest("filter/leave");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setLeaveType(statusArray);
    } catch (error) {
      console.error("Error fetching type:", error);
    }
  };

  useEffect(() => {
    fetchLeaveType();
  }, []);

  useEffect(() => {
    const fetchLeave = async () => {
      if (leaveId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const response = await getRequest(`employee/leave/${leaveId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          const formatStartDate = new Date(response.startDate)
            .toISOString()
            .split("T")[0];
          const formatEndDate = new Date(response.endDate)
            .toISOString()
            .split("T")[0];
          setLeaveData({
            startDate: formatStartDate,
            endDate: formatEndDate,
            reason: response.reason,
            type: response.type,
          });
        } catch (error) {
          console.error("Error fetching Leave:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLeaveData({
          type: "",
          reason: "",
          startDate: "",
          endDate: "",
        });
      }
    };
    fetchLeave();
  }, [leaveId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `employee/leave/${leaveId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        leaveData
      );
      toast.success("Leave Updated Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveData({ ...leaveData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "employee/leave",
        {
          Authorization: `Bearer ${access}`,
        },
        leaveData
      );
      toast.success("Leave Applied Successfully", toastConfig);
      setLeaveData({
        type: "",
        reason: "",
        startDate: "",
        endDate: "",
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          {leaveId ? "Update Leave Application" : "Add Leave Application"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel htmlFor="outlined-adornment-type">
                Leave Type
              </InputLabel>
              <Select
                labelId="outlined-adornment-type-label"
                id="outlined-adornment-type"
                name="type"
                label="Leave Type"
                value={leaveData.type}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              >
                {leaveType.map((leaveOpt) => (
                  <MenuItem key={leaveOpt.value} value={leaveOpt.value}>
                    {leaveOpt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel htmlFor="outlined-adornment-reason">
                Reason
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-reason"
                type="text"
                name="reason"
                label="reason"
                value={leaveData.reason}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel
                htmlFor="outlined-adornment-startDate"
                shrink
                sx={{
                  backgroundColor: "#000",
                  paddingX: "4px",
                  marginLeft: "-4px",
                }}
              >
                Start Date
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-startDate"
                type="date"
                name="startDate"
                label="Start Date"
                value={leaveData.startDate}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel
                htmlFor="outlined-adornment-endDate"
                shrink
                sx={{
                  backgroundColor: "#000",
                  paddingX: "4px",
                  marginLeft: "-4px",
                }}
              >
                End Date
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-endDate"
                type="date"
                name="endDate"
                label="End Date"
                value={leaveData.endDate}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </Grid>
          {leaveId !== null ? (
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleUpdate}
                sx={{
                  bgcolor: "#E5622D",
                  "&:hover": { bgcolor: "#D0521B" },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                ) : (
                  "Update"
                )}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleSubmit}
                sx={{
                  bgcolor: "#E5622D",
                  "&:hover": { bgcolor: "#D0521B" },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                ) : (
                  "Add"
                )}
              </Button>
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleClose}
              sx={{ bgcolor: "#A9A9A9", "&:hover": { bgcolor: "#E1E1E1" } }}
              disabled={loading}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddLeave;
