import React, { useState, useEffect } from "react";
import { getRequest, patchRequest, postRequest } from "../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Hidden,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import invoice from "../../images/invoice.png";
import DeleteIcon from "@mui/icons-material/Delete";

const AddInvoice = ({ open, handleClose, invoiceId }) => {
  const [loading, setLoading] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState([]);
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [invoiceData, setInvoiceData] = useState({
    title: "",
    invoiceNumber: "",
    clientId: "",
    clientName: "",
    projectsList: [],
    currency: "",
    date: "",
    status: "",
  });

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/invoice");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setInvoiceStatus(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const res = await getRequest(`invoice/${invoiceId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          const formatDate = new Date(res.date).toISOString().split("T")[0];
          setInvoiceData({
            title: res.title,
            invoiceNumber: res.invoiceNumber,
            clientId: res.clientId,
            clientName: res.clientName,
            projectsList: Array.isArray(res.projectsList)
              ? res.projectsList.map((item) => ({
                  projectId: item.projectId || "",
                  projectName: item.projectName || "",
                  amount: item.amount || "",
                }))
              : [],
            currency: res.currency,
            date: formatDate,
            status: res.status,
          });
        } catch (error) {
          console.error("Error fetching Invoice:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setInvoiceData({
          title: "",
          invoiceNumber: "",
          clientId: "",
          clientName: "",
          projectsList: [],
          currency: "",
          date: "",
          status: "",
        });
      }
    };
    fetchInvoice();
  }, [invoiceId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `invoice/${invoiceId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        invoiceData
      );
      toast.success("Invoice Updated Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const access = sessionStorage.getItem("access");
      try {
        const response = await getRequest("project", {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });
        setProjects(response);
        const clientMap = new Map();
        response.forEach((proj) => {
          if (!clientMap.has(proj.clientId)) {
            clientMap.set(proj.clientId, {
              clientId: proj.clientId,
              clientName: proj.clientName,
            });
          }
        });
        const clientList = Array.from(clientMap.values());
        setClients(clientList);
      } catch (error) {
        console.error("Error fetching Projects:", error);
      }
    };
    fetchProjects();
  }, []);

  const handleClientChange = (e) => {
    const clientId = e.target.value;
    const filtered = projects.filter((proj) => proj.clientId === clientId);
    setFilteredProjects(filtered);
    const client = clients.find((c) => c.clientId === clientId);
    setInvoiceData((prevData) => ({
      ...prevData,
      clientId,
      clientName: client?.clientName || "",
      projectsList: filtered.map((proj) => ({
        projectId: proj._id,
        projectName: proj.title,
        amount: "",
      })),
    }));
  };

  const handleProjectChange = (e, index) => {
    const selectedProjectId = e.target.value;
    const selectedProject = filteredProjects.find(
      (proj) => proj._id === selectedProjectId
    );

    if (!selectedProject) {
      console.warn(
        `Project with ID ${selectedProjectId} not found in filteredProjects.`
      );
      return;
    }

    const updatedProjectsList = [...invoiceData.projectsList];
    updatedProjectsList[index] = {
      ...updatedProjectsList[index],
      projectId: selectedProject._id,
      projectName: selectedProject.title,
    };

    setInvoiceData((prevData) => ({
      ...prevData,
      projectsList: updatedProjectsList,
    }));
  };

  const handleAmountChange = (e, index) => {
    const { value } = e.target;

    const updatedProjectsList = [...invoiceData.projectsList];
    updatedProjectsList[index] = {
      ...updatedProjectsList[index],
      amount: value,
    };

    setInvoiceData((prevData) => ({
      ...prevData,
      projectsList: updatedProjectsList,
    }));
  };

  const handleRemoveProject = (index) => {
    const updatedProjectsList = invoiceData.projectsList.filter(
      (_, i) => i !== index
    );
    setInvoiceData((prevData) => ({
      ...prevData,
      projectsList: updatedProjectsList,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData({ ...invoiceData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "invoice",
        {
          Authorization: `Bearer ${access}`,
        },
        invoiceData
      );
      toast.success("Invoice Added Successfully", toastConfig);
      setInvoiceData({
        title: "",
        invoiceNumber: "",
        clientId: "",
        clientName: "",
        projectsList: [],
        currency: "",
        date: "",
        status: "",
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          {invoiceId !== null ? "Update Invoice Record" : "Add Invoice Record"}
        </Typography>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item md={4} justifyContent="center" alignContent="center">
              <img
                src={invoice}
                alt="Invoice"
                style={{ width: "100%", height: "80%", borderRadius: "8px" }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-title">
                    Invoice Title
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-title"
                    type="text"
                    name="title"
                    label="Invoice Title"
                    value={invoiceData.title}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-invoiceNumber">
                    Invoice Number
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-invoiceNumber"
                    type="text"
                    name="invoiceNumber"
                    label="Invoice Number"
                    readOnly={invoiceId === null}
                    value={invoiceData.invoiceNumber}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel id="client-select-label">Client Name</InputLabel>
                  <Select
                    labelId="client-select-label"
                    value={invoiceData.clientId}
                    onChange={handleClientChange}
                    label="Client Name"
                  >
                    {clients.map((client) => (
                      <MenuItem key={client.clientId} value={client.clientId}>
                        {client.clientName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel
                    htmlFor="outlined-adornment-date"
                    shrink
                    sx={{
                      backgroundColor: "#000",
                      paddingX: "4px",
                      marginLeft: "-4px",
                    }}
                  >
                    Date
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-date"
                    type="date"
                    name="date"
                    label="Date"
                    value={invoiceData.date}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {invoiceData.projectsList.map((project, index) => (
                  <Grid container spacing={2} key={index} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                        <InputLabel id={`project-select-label-${index}`}>
                          Project Name
                        </InputLabel>
                        <Select
                          labelId={`project-select-label-${index}`}
                          value={project.projectId}
                          onChange={(e) => handleProjectChange(e, index)}
                          label="Project Name"
                        >
                          {filteredProjects.map((proj) => (
                            <MenuItem key={proj._id} value={proj._id}>
                              {proj.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                        <InputLabel
                          htmlFor={`outlined-adornment-amount-${index}`}
                        >
                          Amount
                        </InputLabel>
                        <OutlinedInput
                          id={`outlined-adornment-amount-${index}`}
                          type="text"
                          name="amount"
                          label="Amount"
                          value={project.amount}
                          onChange={(e) => handleAmountChange(e, index)}
                          sx={{ backgroundColor: "transparent" }}
                        />
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleRemoveProject(index)}
                          sx={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} md={6} mt={-2}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel id="currency-label">Currency</InputLabel>
                  <Select
                    labelId="currency-label"
                    id="outlined-adornment-currency"
                    name="currency"
                    label="Currency"
                    value={invoiceData.currency}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    <MenuItem value="USD">Dollar</MenuItem>
                    <MenuItem value="PKR">PKR</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} mt={-2}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-status">
                    Payment Status
                  </InputLabel>
                  <Select
                    labelId="outlined-adornment-status-label"
                    id="outlined-adornment-status"
                    name="status"
                    label="Payment Status"
                    value={invoiceData.status}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    {invoiceStatus.map((statusOpt) => (
                      <MenuItem key={statusOpt.value} value={statusOpt.value}>
                        {statusOpt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-around">
                {invoiceId !== null ? (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleUpdate}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSubmit}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleClose}
                    disabled={loading}
                    sx={{
                      bgcolor: "#A9A9A9",
                      "&:hover": { bgcolor: "#E1E1E1" },
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddInvoice;
