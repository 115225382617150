/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getRequest, delRequest } from "../../axiosRoutes/index";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import AddProject from "./AddProject";
import SideNavBar from "../sidebar/SideNav";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import CloseIcon from "@mui/icons-material/Close";
import AssignProject from "./AssignProject";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";

const Projects = () => {
  const [projectStatus, setProjectStatus] = useState([]);
  const [projects, setProjects] = useState([]);
  const [unassignData, setUnassignData] = useState({
    userId: "",
  });
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [proId, setProId] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [openAssign, setOpenAssign] = useState(false);
  const [assignData, setAssignData] = useState([]);
  const [openUnassign, setOpenUnassign] = useState(false);
  const [projectData, setProjectData] = useState({
    title: "",
    clientName: "",
    manager: "",
    description: "",
    startDate: "",
    deadLine: "",
    status: "",
  });

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/project");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setProjectStatus(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  const fetchProjects = async () => {
    const access = sessionStorage.getItem("access");
    try {
      const response = await getRequest("project", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      const formattedProjects = response.map((project) => {
        if (project.startDate) {
          const startDate = new Date(project.startDate);
          const deadLine = new Date(project.deadLine);
          project.startDate = startDate.toLocaleDateString("en-GB");
          project.deadLine = deadLine.toLocaleDateString("en-GB");
        }
        const statusObj = projectStatus.find(
          (status) => status.value === project.status
        );
        project.status = statusObj ? statusObj.label : project.status;
        return project;
      });

      setProjects(formattedProjects);
    } catch (error) {
      console.error("Error Fetching Projects:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchStatus();
      fetchProjects();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (projectStatus.length > 0) {
      fetchProjects();
    }
  }, [projectStatus]);

  const handleDelete = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const response = await delRequest(`project/${deleteId}`, access);
      fetchProjects();
      handleClose();
      toast.success(response, toastConfig);
    } catch (error) {
      toast.error(error.message, toastConfig);
    }
  };

  const handleAssignOpen = () => {
    setOpenAssign(true);
  };

  const handleAssign = (id) => {
    setProId(id);
    handleAssignOpen();
  };

  const handleUnassignOpen = (id) => {
    setProId(id);
    setOpenUnassign(true);
  };

  const handleProject = (id) => {
    setOpenProject(true);
    setProId(id);
  };

  useEffect(() => {
    if (proId !== null) {
      const fetchProject = async () => {
        const access = sessionStorage.getItem("access");
        try {
          const res = await getRequest(`project/${proId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          const assignedData = res.assignedTo.map((user) => ({
            userId: user.userId,
            name: user.name,
          }));
          setAssignData(assignedData);
          const formatStart = new Date(res.startDate)
            .toISOString()
            .split("T")[0];
          const formatDead = new Date(res.deadLine).toISOString().split("T")[0];
          setProjectData((prevData) => ({
            ...prevData,
            title: res.title || "N/A",
            clientName: res.clientName || "N/A",
            manager: res.manager || "N/A",
            description: res.description || "N/A",
            startDate: formatStart || "N/A",
            deadLine: formatDead || "N/A",
            status: res.status || "N/A",
          }));
        } catch (error) {
          console.error("Error fetching Project:", error);
        }
      };
      fetchProject();
    }
  }, [proId]);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClickOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const selectProject = (id) => {
    setUpdateId(id);
    handleOpen();
  };

  const handleSelectChange = (event) => {
    const selectedUserId = event.target.value;
    setUnassignData((prevState) => ({
      ...prevState,
      userId: selectedUserId,
    }));
  };

  const handleUnassign = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const response = await delRequest(
        `project/${proId}/assignTo`,
        access,
        unassignData
      );
      fetchProjects();
      handleClose();
      toast.success(response, toastConfig);
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenProject(false);
    setOpenAssign(false);
    setProId(null);
    setOpen(false);
    setOpenUnassign(false);
    setAssignData([]);
    fetchProjects();
    setDeleteId(null);
    setUpdateId(null);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FF5722" }} />
        <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <div className="app-container" style={{ color: "#fff", display: "flex" }}>
      <ToastContainer />
      <SideNavBar />
      <div style={{ flex: 1, margin: "0.5rem" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
        >
          <Grid item>
            <h1>Projects</h1>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                backgroundColor: "#fff",
                color: "black",
                "&:hover": {
                  backgroundColor: "#e1e1e1",
                },
              }}
            >
              Add Project
            </Button>
          </Grid>
        </Grid>
        {projects.length === 0 ? (
          <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
            No Data Yet
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Client Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Manager
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Start Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Deadline
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Assigned To
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project, id) => (
                  <TableRow key={id}>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {id + 1}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {project.title}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {project.clientName}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {project.manager || "N/A"}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {project.startDate}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {project.deadLine}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {project.assignedTo && project.assignedTo.length > 0
                        ? project.assignedTo.map((assign, keys) => (
                            <li key={keys}>{assign.name}</li>
                          ))
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {project.status}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      <Tooltip title="Assign" placement="top">
                        <IconButton
                          style={{
                            color: "#fff",
                            backgroundColor: "#1976D2",
                            margin: 2,
                          }}
                          aria-label="edit"
                          size="small"
                          onClick={() => handleAssign(project._id)}
                        >
                          <InventoryOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="UnAssign" placement="top">
                        <IconButton
                          style={{
                            color: "#fff",
                            backgroundColor: "#F44336",
                            margin: 2,
                          }}
                          aria-label="edit"
                          size="small"
                          onClick={() => handleUnassignOpen(project._id)}
                        >
                          <DeleteSweepIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#1976D2",
                          margin: 2,
                        }}
                        aria-label="view"
                        size="small"
                        onClick={() => handleProject(project._id)}
                      >
                        <VisibilityIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#4CAF50",
                          margin: 2,
                        }}
                        aria-label="edit"
                        size="small"
                        onClick={() => selectProject(project._id)}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#F44336",
                          margin: 2,
                        }}
                        aria-label="delete"
                        size="small"
                        onClick={() => handleClickOpen(project._id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "15px",
            },
            "& .MuiDialogTitle-root": {
              padding: "5px",
            },
            "& .MuiDialogContentText-root": {
              color: "#fff",
            },
            "& .MuiDialogContent-root": {
              padding: "5px",
            },
            "& .MuiDialogActions-root": {
              padding: "5px",
            },
          }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are You Sure You Want to Delete this Project?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openProject}
          maxWidth
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "15px",
              maxWidth: "75%",
              border: "2px solid #D0521B",
            },
            "& .MuiDialogContentText-root": {
              color: "#fff",
            },
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "28px",
            }}
          >
            {projectData.title}
            <IconButton
              onClick={handleClose}
              sx={{
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              <Typography
                variant="body1"
                component="div"
                sx={{ borderBottom: "1px solid #D0521B", padding: "1rem" }}
              >
                <b>Title:</b>
                <span style={{ marginLeft: "1rem" }}>{projectData.title}</span>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ borderBottom: "1px solid #D0521B", padding: "1rem" }}
              >
                <b>Client Name:</b>
                <span style={{ marginLeft: "1rem" }}>
                  {projectData.clientName}
                </span>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ borderBottom: "1px solid #D0521B", padding: "1rem" }}
              >
                <b>Description:</b>
                <span style={{ marginLeft: "1rem" }}>
                  {projectData.description}
                </span>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ borderBottom: "1px solid #D0521B", padding: "1rem" }}
              >
                <b>Manager:</b>
                <span style={{ marginLeft: "1rem" }}>
                  {projectData.manager}
                </span>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ borderBottom: "1px solid #D0521B", padding: "1rem" }}
              >
                <b>Status:</b>
                <span style={{ marginLeft: "1rem" }}>{projectData.status}</span>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ borderBottom: "1px solid #D0521B", padding: "1rem" }}
              >
                <b>Start Date:</b>
                <span style={{ marginLeft: "1rem" }}>
                  {projectData.startDate}
                </span>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ borderBottom: "1px solid #D0521B", padding: "1rem" }}
              >
                <b>Dead Line:</b>
                <span style={{ marginLeft: "1rem" }}>
                  {projectData.deadLine}
                </span>
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          maxWidth="sm"
          open={openUnassign}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "20px",
              borderRadius: "8px",
            },
            "& .MuiDialogTitle-root": {
              padding: "10px 5px",
              borderBottom: "1px solid #E5622D",
            },
            "& .MuiDialogContent-root": {
              padding: "10px",
            },
            "& .MuiDialogActions-root": {
              padding: "10px 5px",
              justifyContent: "space-around",
            },
          }}
        >
          <DialogTitle
            sx={{
              typography: "h6",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#E5622D",
            }}
          >
            Confirm UnAssign Project
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: "20px", color: "#fff" }}>
              Are you sure you want to unassign this Project to selected
              employee?
            </DialogContentText>
            {assignData.length === 0 ? (
              <Typography
                variant="h6"
                sx={{ color: "#fff", textAlign: "center", margin: "10px 0" }}
              >
                No Item Assigned Yet
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    color: "#FEFEFE",
                    "& fieldset": {
                      borderColor: "#FEFEFE",
                    },
                    "&:hover fieldset": {
                      borderColor: "#FEFEFE",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FEFEFE",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#FEFEFE",
                  },
                }}
              >
                <InputLabel id="client-select-label">Employee Name</InputLabel>
                <Select
                  labelId="client-select-label"
                  value={unassignData.userId}
                  onChange={handleSelectChange}
                  label="Employee Name"
                >
                  {assignData.map((assign) => (
                    <MenuItem key={assign.userId} value={assign.userId}>
                      {assign.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={assignData.length === 0}
              onClick={handleUnassign}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
                "&.Mui-disabled": {
                  backgroundColor: "#EC916C",
                  color: "#fff",
                },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <AddProject
          open={openModal}
          handleClose={handleClose}
          projectId={updateId}
        />
        <AssignProject
          open={openAssign}
          handleClose={handleClose}
          proId={proId}
        />
      </div>
    </div>
  );
};

export default Projects;
