import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import LogoutIcon from "@mui/icons-material/Logout";
import CompanyLogo from "../../../images/logo.png";

const menuItems = [
  { text: "Profile", icon: <AccountBoxIcon />, path: "/profile" },
  { text: "Fitness Allowance", icon: <FitnessCenterIcon />, path: "/fitness" },
  {
    text: "Medical Allowance",
    icon: <MedicalServicesIcon />,
    path: "/medical",
  },
  { text: "Leaves", icon: <WorkOffIcon />, path: "/employee/leaves" },
];

const SideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          background: "#1C1D21",
          color: "white",
          width: "18vw",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "18vw",
            boxSizing: "border-box",
            background: "#1C1D21",
            color: "white",
          },
          "@media (max-width: 780px)": {
            width: 200,
            "& .MuiDrawer-paper": {
              width: 200,
            },
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <img src={CompanyLogo} alt="Company Logo" style={{ width: "80%" }} />
        </Box>

        <List>
          {menuItems.map((item, index) => (
            <div key={index}>
              <ListItem
                button
                component={Link}
                to={item.path}
                sx={{
                  color: "white",
                  borderLeft:
                    location.pathname === item.path
                      ? "4px solid #FF5722"
                      : "4px solid transparent",
                }}
              >
                <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
              <Divider sx={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }} />
            </div>
          ))}
          <ListItem button onClick={handleClickOpen}>
            <ListItemIcon sx={{ color: "white" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#212121",
            color: "#fff",
            padding: "15px",
          },
          "& .MuiDialogTitle-root": {
            padding: "5px",
          },
          "& .MuiDialogContentText-root": {
            color: "#fff",
          },
          "& .MuiDialogContent-root": {
            padding: "10px",
          },
          "& .MuiDialogActions-root": {
            padding: "5px",
          },
        }}
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "#A6A6A6",
              color: "#fff",
              "&:hover": { backgroundColor: "#B8B8B8" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogout}
            sx={{
              backgroundColor: "#E5622D",
              color: "#fff",
              "&:hover": { backgroundColor: "#D0521B" },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SideNav;
