import React, { useState, useEffect } from "react";
import { getRequest, patchRequest, postRequest } from "../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Hidden,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import item from "../../images/item.png";

const AddInventory = ({ open, handleClose, inventoryId }) => {
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [itemData, setItemData] = useState({
    title: "",
    price: 0,
    category: "",
    condition: "",
    quantity: 0,
    purchaseDate: "",
    propertiesList: [
      {
        id: "",
        properties: [],
      },
    ],
  });

  const fetchCondition = async () => {
    try {
      const response = await getRequest("filter/condition");
      const conditionArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setConditions(conditionArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  useEffect(() => {
    fetchCondition();
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      if (inventoryId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const res = await getRequest(`admin/inventory/${inventoryId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          const date = new Date(res.purchaseDate).toISOString().split("T")[0];
          setItemData((prevData) => ({
            ...prevData,
            title: res.title,
            price: res.price,
            category: res.category,
            condition: res.condition,
            quantity: res.quantity,
            purchaseDate: date,
            propertiesList: Array.isArray(res.propertiesList)
              ? res.propertiesList.map((item) => ({
                  id: item.id || "",
                  properties: item.properties || [],
                }))
              : [{ id: "", properties: [] }],
          }));
        } catch (error) {
          console.error("Error fetching Item:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setItemData({
          title: "",
          price: 0,
          category: "",
          condition: "",
          quantity: 0,
          purchaseDate: "",
          propertiesList: [
            {
              id: "",
              properties: [],
            },
          ],
        });
      }
    };
    fetchItems();
  }, [inventoryId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `admin/inventory/${inventoryId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        itemData
      );
      toast.success("Items Updated Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "propertiesID") {
      const updatedPropertiesList = [...itemData.propertiesList];
      updatedPropertiesList[0] = { ...updatedPropertiesList[0], id: value };
      setItemData({ ...itemData, propertiesList: updatedPropertiesList });
    } else if (name === "properties") {
      const updatedPropertiesList = [...itemData.propertiesList];
      updatedPropertiesList[0] = {
        ...updatedPropertiesList[0],
        properties: value,
      };
      setItemData({ ...itemData, propertiesList: updatedPropertiesList });
    } else {
      setItemData({ ...itemData, [name]: value });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === "properties") {
      const valuesArray = value
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item);
      const updatedPropertiesList = [...itemData.propertiesList];
      updatedPropertiesList[0] = {
        ...updatedPropertiesList[0],
        properties: valuesArray,
      };
      setItemData({ ...itemData, propertiesList: updatedPropertiesList });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "admin/inventory",
        {
          Authorization: `Bearer ${access}`,
        },
        itemData
      );
      toast.success("Items Added Successfully", toastConfig);
      setItemData({
        title: "",
        price: 0,
        category: "",
        condition: "",
        quantity: 0,
        purchaseDate: "",
        propertiesList: [
          {
            id: "",
            properties: [],
          },
        ],
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          {inventoryId !== null ? "Update Item Record" : "Add Item Record"}
        </Typography>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item md={4}>
              <img
                src={item}
                alt="item"
                style={{
                  width: "100%",
                  height: "80%",
                  borderRadius: "8px",
                  color: "#E5622D",
                }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-title">
                    Title
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-title"
                    type="text"
                    name="title"
                    label="Title"
                    value={itemData.title}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-quantity">
                    Quantity
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-quantity"
                    type="number"
                    name="quantity"
                    label="Quantity"
                    value={itemData.quantity}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-category">
                    Category
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-category"
                    type="text"
                    name="category"
                    label="Category"
                    value={itemData.category}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-price">
                    Price
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-price"
                    type="text"
                    name="price"
                    label="Price"
                    value={itemData.price}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-Condition">
                    Condition
                  </InputLabel>
                  <Select
                    labelId="outlined-adornment-condition-label"
                    id="outlined-adornment-condition"
                    name="condition"
                    label="Condition"
                    value={itemData.condition}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    {conditions.map((conditionOpt) => (
                      <MenuItem
                        key={conditionOpt.value}
                        value={conditionOpt.value}
                      >
                        {conditionOpt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel
                    htmlFor="outlined-adornment-purchaseDate"
                    shrink
                    sx={{
                      backgroundColor: "#000",
                      paddingX: "4px",
                      marginLeft: "-4px",
                    }}
                  >
                    Purchase Date
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-purchaseDate"
                    type="date"
                    name="purchaseDate"
                    label="Purchase Date"
                    value={itemData.purchaseDate}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <span>
                  <b>Properties List</b>
                </span>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-propertiesID">
                    Properties ID
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-propertiesID"
                    type="text"
                    name="propertiesID"
                    label="Properties ID"
                    value={
                      itemData.propertiesList && itemData.propertiesList[0]
                        ? itemData.propertiesList[0].id
                        : ""
                    }
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-properties">
                    Properties (comma-separated)
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-properties"
                    type="text"
                    name="properties"
                    label="Properties (comma-separated)"
                    value={
                      itemData.propertiesList && itemData.propertiesList[0]
                        ? itemData.propertiesList[0].properties
                        : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-around">
                {inventoryId !== null ? (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleUpdate}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSubmit}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleClose}
                    disabled={loading}
                    sx={{
                      bgcolor: "#A9A9A9",
                      "&:hover": { bgcolor: "#E1E1E1" },
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddInventory;
