import React, { useState, useEffect } from "react";
import { getRequest, patchRequest, postRequest } from "../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Hidden,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import client from "../../images/client.png";

const AddClient = ({ open, handleClose, clientId }) => {
  const [loading, setLoading] = useState(false);
  const [genders, setGenders] = useState([]);
  const [clientData, setClientData] = useState({
    name: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    company: "",
  });

  const fetchGender = async () => {
    try {
      const response = await getRequest("filter/gender");
      const genderArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setGenders(genderArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  useEffect(() => {
    fetchGender();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      if (clientId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const response = await getRequest(`client/${clientId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          setClientData({
            name: response.name,
            email: response.email,
            phone: response.phone,
            gender: response.gender,
            address: response.address,
            company: response.company,
          });
        } catch (error) {
          console.error("Error fetching Client:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setClientData({
          name: "",
          email: "",
          phone: "",
          gender: "",
          address: "",
          company: "",
        });
      }
    };
    fetchClients();
  }, [clientId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `client/${clientId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        clientData
      );
      toast.success("Client Updated Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "client",
        {
          Authorization: `Bearer ${access}`,
        },
        clientData
      );
      toast.success("Client Added Successfully", toastConfig);
      setClientData({
        name: "",
        email: "",
        phone: "",
        gender: "",
        address: "",
        company: "",
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item md={4}>
              <img
                src={client}
                alt="Client"
                style={{ width: "100%", height: "90%", borderRadius: "8px" }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              fontWeight="bold"
              textAlign="center"
              component="h1"
              sx={{ mb: 2 }}
            >
              {clientId ? "Update Client" : "Add Client"}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-name">
                    Client Name
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-name"
                    type="text"
                    name="name"
                    label="Client Name"
                    value={clientData.name}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-email">
                    Email Address
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email"
                    type="email"
                    name="email"
                    label="Email Address"
                    value={clientData.email}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-phone">
                    Phone Number
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-phone"
                    type="text"
                    name="phone"
                    label="Phone Number"
                    value={clientData.phone}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-gender">
                    Gender
                  </InputLabel>
                  <Select
                    labelId="outlined-adornment-gender-label"
                    id="outlined-adornment-gender"
                    name="gender"
                    label="Gender"
                    value={clientData.gender}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    {genders.map((genderOpt) => (
                      <MenuItem key={genderOpt.value} value={genderOpt.value}>
                        {genderOpt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-company">
                    Company Name
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-company"
                    type="text"
                    name="company"
                    label="Company"
                    value={clientData.company}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-address">
                    Client Address
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-address"
                    type="text"
                    name="address"
                    label="Client Address"
                    value={clientData.address}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              {clientId !== null ? (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleUpdate}
                    sx={{
                      bgcolor: "#E5622D",
                      "&:hover": { bgcolor: "#D0521B" },
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    sx={{
                      bgcolor: "#E5622D",
                      "&:hover": { bgcolor: "#D0521B" },
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                    ) : (
                      "Add"
                    )}
                  </Button>
                </Grid>
              )}
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleClose}
                  sx={{ bgcolor: "#A9A9A9", "&:hover": { bgcolor: "#E1E1E1" } }}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddClient;
