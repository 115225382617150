import React, { useState, useEffect } from "react";
import { getRequest, patchRequest, postRequest } from "../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Hidden,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import employee from "../../images/employee.png";

const AddSalary = ({ open, handleClose, salaryId }) => {
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [salaryData, setSalaryData] = useState({
    userId: "",
    name: "",
    grossSalary: 0,
    baseSalary: 0,
    houseAllowance: 0,
    utility: 0,
    bonus: 0,
    overtime: 0,
    deduction: 0,
    salaryMonth: "",
    status: "",
  });

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/status");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setStatuses(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  useEffect(() => {
    const fetchSalary = async () => {
      if (salaryId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const res = await getRequest(`admin/salary/${salaryId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          setSalaryData({
            userId: res.userId,
            name: res.name,
            grossSalary: res.grossSalary,
            baseSalary: res.baseSalary,
            houseAllowance: res.houseAllowance,
            utility: res.utility,
            bonus: res.bonus,
            overtime: res.overtime,
            deduction: res.deduction,
            salaryMonth: res.salaryMonth,
            status: res.status,
          });
        } catch (error) {
          console.error("Error fetching Salary:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setSalaryData({
          userId: "",
          name: "",
          grossSalary: 0,
          baseSalary: 0,
          houseAllowance: 0,
          utility: 0,
          bonus: 0,
          overtime: 0,
          deduction: 0,
          salaryMonth: "",
          status: "",
        });
      }
    };
    fetchSalary();
  }, [salaryId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `admin/salary/${salaryId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        salaryData
      );
      toast.success("Salary Updated Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      const access = sessionStorage.getItem("access");
      try {
        const response = await getRequest("employee", {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });
        setEmployees(response);
      } catch (error) {
        console.error("Error fetching Salary:", error);
      }
    };
    fetchEmployees();
  }, []);

  const handleSelectChange = (e) => {
    const empId = e.target.value;
    const employee = employees.find((emp) => emp.userId._id === empId);
    const empName = employee.userId.name;
    setSalaryData((prevData) => ({
      ...prevData,
      userId: empId,
      name: empName,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "grossSalary") {
      const base = value * 0.6;
      const house = value * 0.2;
      const bill = value * 0.2;
      setSalaryData(
        (salaryData.baseSalary = base),
        (salaryData.houseAllowance = house),
        (salaryData.utility = bill)
      );
    }
    setSalaryData({ ...salaryData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "admin/salary",
        {
          Authorization: `Bearer ${access}`,
        },
        salaryData
      );
      toast.success("Salary Added Successfully", toastConfig);
      setSalaryData({
        userId: "",
        name: "",
        grossSalary: 0,
        baseSalary: 0,
        houseAllowance: 0,
        utility: 0,
        bonus: 0,
        overtime: 0,
        deduction: 0,
        salaryMonth: "",
        status: "",
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          {salaryId !== null ? "Update Salary Record" : "Add Salary Record"}
        </Typography>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item md={4}>
              <img
                src={employee}
                alt="Employee"
                style={{ width: "110%", height: "90%", borderRadius: "8px" }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel id="employee-select-label">
                    Employee Name
                  </InputLabel>
                  <Select
                    labelId="employee-select-label"
                    value={salaryData.userId}
                    onChange={handleSelectChange}
                    readOnly
                    label="Employee Name"
                  >
                    {employees.map((employee) => (
                      <MenuItem
                        key={employee.userId._id}
                        value={employee.userId._id}
                      >
                        {employee.userId.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-grossSalary">
                    Gross Salary
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-grossSalary"
                    type="text"
                    name="grossSalary"
                    label="Gross Salary"
                    value={salaryData.grossSalary}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-baseSalary">
                    Base Salary
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-baseSalary"
                    type="text"
                    name="baseSalary"
                    label="Base Salary"
                    readOnly
                    value={salaryData.baseSalary}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-houseAllowance">
                    House Allowance
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-houseAllowance"
                    type="text"
                    name="houseAllowance"
                    label="House Allowance"
                    readOnly
                    value={salaryData.houseAllowance}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-utility">
                    Utility
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-utility"
                    type="text"
                    name="utility"
                    label="Utility"
                    readOnly
                    value={salaryData.utility}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-bonus">
                    Bonus
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-bonus"
                    type="text"
                    name="bonus"
                    label="Bonus"
                    value={salaryData.bonus}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-overtime">
                    Overtime
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-overtime"
                    type="text"
                    name="overtime"
                    label="Overtime"
                    value={salaryData.overtime}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-deduction">
                    Deduction
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-deduction"
                    type="text"
                    name="deduction"
                    label="Deduction"
                    value={salaryData.deduction}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-status">
                    Pay Status
                  </InputLabel>
                  <Select
                    labelId="outlined-adornment-status-label"
                    id="outlined-adornment-status"
                    name="status"
                    label="Pay Status"
                    value={salaryData.status}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    {statuses.map((statusOpt) => (
                      <MenuItem key={statusOpt.value} value={statusOpt.value}>
                        {statusOpt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-around">
                {salaryId !== null ? (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleUpdate}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSubmit}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleClose}
                    disabled={loading}
                    sx={{
                      bgcolor: "#A9A9A9",
                      "&:hover": { bgcolor: "#E1E1E1", color: "#000" },
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddSalary;
