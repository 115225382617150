import React, { useState, useEffect } from "react";
import { getRequest, patchRequest, postRequest } from "../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Hidden,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import employee from "../../images/employee.png";

const AddEmployee = ({ open, handleClose, employeeId }) => {
  const [loading, setLoading] = useState(false);
  const [genders, setGenders] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employeeData, setEmployeeData] = useState({
    name: "",
    email: "",
    designation: "",
    department: "",
    cnic: "",
    phone: "",
    dob: "",
    gender: "",
    address: "",
    joiningDate: "",
    role: "",
    salary: 0,
    bankAccountNumber: "",
    bankAccountName: "",
    bankName: "",
  });

  const fetchGender = async () => {
    try {
      const response = await getRequest("filter/gender");
      const genderArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setGenders(genderArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  const fetchRole = async () => {
    try {
      const response = await getRequest("filter/roles");
      const rolesArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setRoles(rolesArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  useEffect(() => {
    fetchGender();
    fetchRole();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (employeeId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const response = await getRequest(`employee/${employeeId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          const birthDate = new Date(response.dob).toISOString().split("T")[0];
          const joining = new Date(response.joiningDate)
            .toISOString()
            .split("T")[0];
          setEmployeeData({
            name: response.name,
            email: response.email,
            designation: response.designation,
            department: response.department,
            cnic: response.cnic,
            phone: response.phone,
            dob: birthDate,
            gender: response.gender,
            address: response.address,
            joiningDate: joining,
            role: response.role,
            salary: response.salary,
            bankAccountNumber: response.bankAccountNumber,
            bankAccountName: response.bankAccountName,
            bankName: response.bankName,
          });
        } catch (error) {
          console.error("Error fetching employee:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setEmployeeData({
          name: "",
          email: "",
          designation: "",
          department: "",
          cnic: "",
          phone: "",
          dob: "",
          gender: "",
          address: "",
          joiningDate: "",
          role: "",
          salary: 0,
          bankAccountNumber: "",
          bankAccountName: "",
          bankName: "",
        });
      }
    };
    fetchEmployees();
  }, [employeeId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `employee/${employeeId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        employeeData
      );
      toast.success("Employee Updated Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData({ ...employeeData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "auth/register",
        {
          Authorization: `Bearer ${access}`,
        },
        employeeData
      );
      toast.success("Employee Added Successfully", toastConfig);
      setEmployeeData({
        name: "",
        email: "",
        designation: "",
        department: "",
        cnic: "",
        phone: "",
        dob: "",
        gender: "",
        address: "",
        joiningDate: "",
        role: "",
        salary: 0,
        bankAccountNumber: "",
        bankAccountName: "",
        bankName: "",
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          {employeeId ? "Update Employee" : "Add Employee"}
        </Typography>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item md={5}>
              <img
                src={employee}
                alt="Employee"
                style={{ width: "100%", height: "90%", borderRadius: "8px" }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-name">
                    Employee Name
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-name"
                    type="text"
                    name="name"
                    label="Employee Name"
                    value={employeeData.name}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-email">
                    Email Address
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email"
                    type="email"
                    name="email"
                    label="Email Address"
                    value={employeeData.email}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-designation">
                    Designation
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-designation"
                    type="text"
                    name="designation"
                    label="Designation"
                    value={employeeData.designation}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-department">
                    Department
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-department"
                    type="text"
                    name="department"
                    label="Department"
                    value={employeeData.department}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-cnic">
                    CNIC
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-cnic"
                    type="text"
                    name="cnic"
                    label="CNIC"
                    value={employeeData.cnic}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-phone">
                    Phone Number
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-phone"
                    type="text"
                    name="phone"
                    label="Phone Number"
                    value={employeeData.phone}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel
                    htmlFor="outlined-adornment-dob"
                    shrink
                    sx={{
                      backgroundColor: "#000",
                      paddingX: "4px",
                      marginLeft: "-4px",
                    }}
                  >
                    Date of Birth
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-dob"
                    type="date"
                    name="dob"
                    label="Date of Birth"
                    value={employeeData.dob}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-gender">
                    Gender
                  </InputLabel>
                  <Select
                    labelId="outlined-adornment-gender-label"
                    id="outlined-adornment-gender"
                    name="gender"
                    label="Gender"
                    value={employeeData.gender}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    {genders.map((genderOpt) => (
                      <MenuItem key={genderOpt.value} value={genderOpt.value}>
                        {genderOpt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-address">
                    Residential Address
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-address"
                    type="text"
                    name="address"
                    label="Residential Address"
                    value={employeeData.address}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel
                    htmlFor="outlined-adornment-joiningDate"
                    shrink
                    sx={{
                      backgroundColor: "#000",
                      paddingX: "4px",
                      marginLeft: "-4px",
                    }}
                  >
                    Joining Date
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-joiningDate"
                    type="date"
                    name="joiningDate"
                    label="Joining Date"
                    value={employeeData.joiningDate}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-role">
                    Role
                  </InputLabel>
                  <Select
                    labelId="outlined-adornment-role-label"
                    id="outlined-adornment-role"
                    name="role"
                    label="Role"
                    value={employeeData.role}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    {roles.map((roleOption) => (
                      <MenuItem key={roleOption.value} value={roleOption.value}>
                        {roleOption.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-salary">
                    Salary
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-salary"
                    type="text"
                    name="salary"
                    label="Salary"
                    value={employeeData.salary}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-accountNo">
                    Bank Account No
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-accountNo"
                    type="text"
                    name="bankAccountNumber"
                    label="Bank Account No"
                    value={employeeData.bankAccountNumber}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-accountName">
                    Bank Account Name
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-accountName"
                    type="text"
                    name="bankAccountName"
                    label="Bank Account Name"
                    value={employeeData.bankAccountName}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-bankName">
                    Bank Name
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-bankName"
                    type="text"
                    name="bankName"
                    label="Bank Name"
                    value={employeeData.bankName}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              {employeeId !== null ? (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleUpdate}
                    sx={{
                      bgcolor: "#E5622D",
                      "&:hover": { bgcolor: "#D0521B" },
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    sx={{
                      bgcolor: "#E5622D",
                      "&:hover": { bgcolor: "#D0521B" },
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                    ) : (
                      "Add"
                    )}
                  </Button>
                </Grid>
              )}
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleClose}
                  disabled={loading}
                  sx={{ bgcolor: "#A9A9A9", "&:hover": { bgcolor: "#E1E1E1" } }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddEmployee;
