import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Protected(props) {
  const navigate = useNavigate();
  const { Component, allowedRoles } = props;

  useEffect(() => {
    const access = sessionStorage.getItem("access");
    const expires = sessionStorage.getItem("expires");
    const refresh = sessionStorage.getItem("refresh");
    const role = sessionStorage.getItem("role");

    if (access && expires && refresh && role) {
      const nowTime = new Date().getTime();
      const expiryTime = new Date(expires).getTime();

      if (nowTime > expiryTime) {
        sessionStorage.clear();
        alert("Session Expired");
        navigate("/login");
      } else {
        if (!allowedRoles.includes(role)) {
          alert("Unauthorized Access");
          navigate(-1);
        }
      }
    } else {
      navigate("/login");
    }
  }, [navigate, allowedRoles]);

  return (
    <>
      <Component />
    </>
  );
}

export default Protected;
