import React, { useState, useEffect } from "react";
import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../../axiosRoutes/toastConfig";

const AddMedical = ({ open, handleClose, medicalId }) => {
  const [loading, setLoading] = useState(false);
  const [medicalData, setMedicalData] = useState({
    userId: "",
    date: "",
    reason: "",
    comments: "",
    amount: "",
  });

  useEffect(() => {
    const fetchMedical = async () => {
      if (medicalId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const response = await getRequest(
            `employee/medicalAllowance/${medicalId}`,
            {
              headers: {
                Authorization: `Bearer ${access}`,
              },
            }
          );
          const formatDate = new Date(response.date)
            .toISOString()
            .split("T")[0];
          setMedicalData({
            userId: response.userId,
            date: formatDate,
            reason: response.reason,
            comments: response.comments,
            amount: response.amount,
          });
        } catch (error) {
          console.error("Error fetching Medical Allowances:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setMedicalData({
          userId: "",
          date: "",
          reason: "",
          comments: "",
          amount: "",
        });
      }
    };
    fetchMedical();
  }, [medicalId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `employee/medicalAllowance/${medicalId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        medicalData
      );
      toast.success("Medical Updated Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const id = sessionStorage.getItem("userId");
    const { name, value } = e.target;
    setMedicalData({ ...medicalData, [name]: value });
    setMedicalData((prevData) => ({
      ...prevData,
      userId: id,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "employee/medicalAllowance",
        {
          Authorization: `Bearer ${access}`,
        },
        medicalData
      );
      toast.success("Medical Added Successfully", toastConfig);
      setMedicalData({
        userId: "",
        date: "",
        reason: "",
        comments: "",
        amount: "",
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          {medicalId ? "Update Medical Allowance" : "Add Medical Allowance"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel htmlFor="outlined-adornment-Reason">
                Reason
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-reason"
                type="text"
                name="reason"
                label="Reason"
                value={medicalData.reason}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel htmlFor="outlined-adornment-Amount">
                Amount
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                type="text"
                name="amount"
                label="Amount"
                value={medicalData.amount}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel htmlFor="outlined-adornment-Comments">
                Comments
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-comments"
                type="text"
                name="comments"
                label="Comments"
                value={medicalData.comments}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
              <InputLabel
                htmlFor="outlined-adornment-date"
                shrink
                sx={{
                  backgroundColor: "#000",
                  paddingX: "4px",
                  marginLeft: "-4px",
                }}
              >
                Date
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-date"
                type="date"
                name="date"
                label="Date"
                value={medicalData.date}
                onChange={handleChange}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </Grid>
          {medicalId !== null ? (
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleUpdate}
                sx={{
                  bgcolor: "#E5622D",
                  "&:hover": { bgcolor: "#D0521B" },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                ) : (
                  "Update"
                )}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleSubmit}
                sx={{
                  bgcolor: "#E5622D",
                  "&:hover": { bgcolor: "#D0521B" },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                ) : (
                  "Add"
                )}
              </Button>
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleClose}
              disabled={loading}
              sx={{ bgcolor: "#A9A9A9", "&:hover": { bgcolor: "#E1E1E1" } }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddMedical;
