import React, { useState, useEffect } from "react";
import { getRequest } from "../../axiosRoutes/index";
import { CircularProgress, Grid, Paper, Typography, Box } from "@mui/material";
import SideNav from "../sidebar/SideNav";
import admin from "../../images/adminDashboard.jpg";

const AdminDashboard = () => {
  const [countEmployee, setCountEmployee] = useState(0);
  const [countClient, setCountClient] = useState(0);
  const [countProject, setCountProject] = useState(0);
  const [countInventory, setCountInventory] = useState(0);

  const [loading, setLoading] = useState(true);

  const fetchItemCount = async (url, setCount) => {
    const access = sessionStorage.getItem("access");
    try {
      const response = await getRequest(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      setCount(response.length);
    } catch (error) {
      console.error(`Error Fetching ${url}:`, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItemCount("employee", setCountEmployee);
    fetchItemCount("client", setCountClient);
    fetchItemCount("project", setCountProject);
    fetchItemCount("admin/inventory", setCountInventory);
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FF5722" }} />
        <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <div className="app-container" style={{ color: "#fff", display: "flex" }}>
      <SideNav />
      <div style={{ flex: 1, margin: "0.5rem" }}>
        <Box sx={{ flexGrow: 1, padding: "20px" }}>
          <Grid container spacing={2} justifyContent="flex start">
            {[
              { name: "Employees", count: countEmployee },
              { name: "Clients", count: countClient },
              { name: "Projects", count: countProject },
              { name: "Office Inventory", count: countInventory },
            ].map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item.name}>
                <Paper
                  elevation={4}
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    textAlign: "center",
                    padding: "60px 20px",
                    borderRadius: "10px",
                    border: "2px solid #FF5722",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold">
                    {item.name}
                  </Typography>
                  <Typography variant="h6">{item.count}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <Box
            style={{
              marginTop: "20px",
            }}
          >
            <img
              src={admin}
              alt="Admin Background"
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default AdminDashboard;
