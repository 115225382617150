import React, { useState, useEffect } from "react";
import { getRequest, putRequest } from "../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Hidden,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import item from "../../images/item.png";

const AssignItem = ({ open, handleClose, itemId }) => {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [assignData, setAssignData] = useState({
    userId: "",
    name: "",
    quantity: 0,
  });
  useEffect(() => {
    const fetchEmployees = async () => {
      const access = sessionStorage.getItem("access");
      try {
        setLoading(true);
        const response = await getRequest("employee", {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });
        setEmployees(response);
      } catch (error) {
        console.error("Error fetching Employees:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  const handleSelectChange = (e) => {
    const empId = e.target.value;
    const employee = employees.find((emp) => emp.userId._id === empId);
    const empName = employee.name;
    setAssignData((prevData) => ({
      ...prevData,
      userId: empId,
      name: empName,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssignData({ ...assignData, [name]: value });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const access = sessionStorage.getItem("access");
      await putRequest(
        `admin/inventory/${itemId}/assignTo`,
        {
          Authorization: `Bearer ${access}`,
        },
        assignData
      );
      toast.success("Items Assigned Successfully", toastConfig);
      setAssignData({
        userId: "",
        name: "",
        quantity: 0,
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          Assign Item Record
        </Typography>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item md={4}>
              <img
                src={item}
                alt="item"
                style={{
                  width: "90%",
                  height: "90%",
                  borderRadius: "8px",
                  color: "#E5622D",
                }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel id="client-select-label">
                    Employee Name
                  </InputLabel>
                  <Select
                    labelId="client-select-label"
                    value={assignData.userId}
                    onChange={handleSelectChange}
                    label="Employee Name"
                  >
                    {employees.map((employee) => (
                      <MenuItem key={employee._id} value={employee.userId._id}>
                        {employee.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-quantity">
                    Quantity
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-quantity"
                    type="number"
                    name="quantity"
                    label="Quantity"
                    value={assignData.quantity}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-around">
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    sx={{
                      bgcolor: "#E5622D",
                      "&:hover": { bgcolor: "#D0521B" },
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                    ) : (
                      "Assign Item"
                    )}
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleClose}
                    disabled={loading}
                    sx={{
                      bgcolor: "#A9A9A9",
                      "&:hover": { bgcolor: "#E1E1E1" },
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AssignItem;
