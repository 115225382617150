/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getRequest, delRequest, postRequest } from "../../axiosRoutes/index";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SideNavBar from "../sidebar/SideNav";
import AddSalary from "./AddSalary";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toastConfig } from "../../axiosRoutes/toastConfig";

const getCurrentMonthYear = () => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();
  return `${month}/${year}`;
};

const Salaries = () => {
  const [salaries, setSalaries] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [totalSalaries, setTotalSalaries] = useState({
    payableSalary: 0,
    month: "",
  });
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthYear());

  const handleMonthChange = (date) => {
    if (date) {
      const formattedDate = dayjs(date).format("M/YYYY");
      setSelectedMonth(formattedDate);
      fetchSalaries();
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/status");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setStatuses(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  const fetchSalaries = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const res = await postRequest(
        "admin/salary/month",
        {
          Authorization: `Bearer ${access}`,
        },
        { salaryMonth: selectedMonth }
      );
      const totalAmount = res.data.reduce(
        (total, salary) => total + salary.netSalary,
        0
      );
      const [curMonth, curYear] = selectedMonth.split("/");
      const curDate = new Date(`${curYear}-${curMonth}-01`);
      const formattedMonth = curDate.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
      const formattedSalaries = res.data.map((salary) => {
        const statusObj = statuses.find(
          (status) => status.value === salary.status
        );
        salary.status = statusObj ? statusObj.label : salary.status;
        return salary;
      });
      setSalaries(formattedSalaries);
      setTotalSalaries((prevData) => ({
        ...prevData,
        payableSalary: totalAmount,
        month: formattedMonth,
      }));
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchStatus();
      fetchSalaries();
    };
    fetchData();
  }, [selectedMonth]);

  useEffect(() => {
    if (statuses.length > 0) {
      fetchSalaries();
    }
  }, [statuses]);

  const handleDelete = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const response = await delRequest(`admin/salary/${deleteId}`, access);
      handleClose();
      toast.success(response, toastConfig);
      fetchSalaries();
    } catch (error) {
      toast.error(error.message, toastConfig);
    }
  };

  const handleSalary = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const res = await postRequest(
        "admin/salary/generate-new-salary-records",
        {
          Authorization: `Bearer ${access}`,
        }
      );
      toast.success(res.data, toastConfig);
      setTimeout(fetchSalaries, 1000);
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    }
  };

  const handleSalaryMail = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const res = await getRequest("admin/salary/generate-salary-slips", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      toast.success(res, toastConfig);
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClickOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleMail = async (id, month) => {
    try {
      const access = sessionStorage.getItem("access");
      const res = await postRequest(
        `admin/salary/${id}`,
        {
          Authorization: `Bearer ${access}`,
        },
        { salaryMonth: month }
      );
      toast.success(res.data, toastConfig);
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    }
  };

  const selectSalary = (id) => {
    setUpdateId(id);
    handleOpen();
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
    fetchSalaries();
    setDeleteId(null);
    setUpdateId(null);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FF5722" }} />
        <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <div className="app-container" style={{ color: "#fff", display: "flex" }}>
      <ToastContainer />
      <SideNavBar />
      <div style={{ flex: 1, margin: "0.5rem" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
        >
          <Grid item>
            <h1>Salaries</h1>
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid
              item
              style={{
                border: "1px solid #D0521B",
                borderRadius: "5px",
                padding: "0.25rem 1rem",
              }}
              mr={2}
            >
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {totalSalaries.payableSalary}
              </span>
              <span style={{ fontSize: "14px" }}>
                Total Salaries Payable in {totalSalaries.month}
              </span>
            </Grid>
            <Grid item mr={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Salary Month"
                  views={["month", "year"]}
                  onChange={handleMonthChange}
                  inputFormat="MM/YYYY"
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "#fff",
                      borderColor: "#FF5722",
                      "& fieldset": {
                        borderColor: "#FF5722",
                      },
                      "&:hover fieldset": {
                        borderColor: "#FF5722",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#FF5722",
                      },
                    },
                    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                      color: "#fff",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#fff",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item mr={2}>
              <Button
                variant="contained"
                onClick={handleSalaryMail}
                sx={{
                  backgroundColor: "#fff",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#e1e1e1",
                  },
                }}
              >
                Mail Salary Slips
              </Button>
            </Grid>
            <Grid item mr={2}>
              <Button
                variant="contained"
                onClick={handleSalary}
                sx={{
                  backgroundColor: "#fff",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#e1e1e1",
                  },
                }}
              >
                Generate Salaries
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {salaries.length === 0 ? (
          <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
            No Data Yet
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Employee Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Salary Month
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Gross Salary
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Net Salary
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Deduction
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salaries.map((salary, id) => (
                  <TableRow key={id}>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {id + 1}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {salary.name}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {salary.salaryMonth}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {salary.grossSalary}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {salary.netSalary}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {salary.deduction}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {salary.status}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#1976D2",
                          margin: 2,
                        }}
                        aria-label="view"
                        size="small"
                        onClick={() =>
                          handleMail(salary.userId, salary.salaryMonth)
                        }
                      >
                        <EmailOutlinedIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#4CAF50",
                          margin: 2,
                        }}
                        aria-label="edit"
                        size="small"
                        onClick={() => selectSalary(salary._id)}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#F44336",
                          margin: 2,
                        }}
                        aria-label="delete"
                        size="small"
                        onClick={() => handleClickOpen(salary._id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "15px",
            },
            "& .MuiDialogTitle-root": {
              padding: "5px",
            },
            "& .MuiDialogContentText-root": {
              color: "#fff",
            },
            "& .MuiDialogContent-root": {
              padding: "5px",
            },
            "& .MuiDialogActions-root": {
              padding: "5px",
            },
          }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this salary?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <AddSalary
          open={openModal}
          handleClose={handleClose}
          salaryId={updateId}
        />
      </div>
    </div>
  );
};

export default Salaries;
