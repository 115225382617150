import React, { useState, useEffect } from "react";
import { getRequest, patchRequest, postRequest } from "../../axiosRoutes/index";
import {
  Button,
  Modal,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Grid,
  Hidden,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";

const AddProject = ({ open, handleClose, projectId }) => {
  const [loading, setLoading] = useState(false);
  const [projectStatus, setProjectStatus] = useState([]);
  const [clients, setClients] = useState([]);
  const [projectData, setProjectData] = useState({
    title: "",
    clientId: "",
    clientName: "",
    manager: "",
    description: "",
    startDate: "",
    deadLine: "",
    status: "",
  });

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/project");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setProjectStatus(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId !== null) {
        const access = sessionStorage.getItem("access");
        try {
          setLoading(true);
          const res = await getRequest(`project/${projectId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          const formatStart = new Date(res.startDate)
            .toISOString()
            .split("T")[0];
          const formatDead = new Date(res.deadLine).toISOString().split("T")[0];
          setProjectData({
            title: res.title,
            clientId: res.clientId,
            clientName: res.clientName,
            manager: res.manager,
            description: res.description,
            startDate: formatStart,
            deadLine: formatDead,
            status: res.status,
          });
        } catch (error) {
          console.error("Error fetching Project:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setProjectData({
          title: "",
          clientId: "",
          clientName: "",
          manager: "",
          description: "",
          startDate: "",
          deadLine: "",
          status: "",
        });
      }
    };
    fetchProject();
  }, [projectId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await patchRequest(
        `project/${projectId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        projectData
      );
      toast.success("Project Updated Successfully", toastConfig);
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      const access = sessionStorage.getItem("access");
      try {
        const response = await getRequest("client", {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });
        setClients(response);
      } catch (error) {
        console.error("Error fetching Clients:", error);
      }
    };
    fetchClients();
  }, []);

  const handleSelectChange = (e) => {
    const cId = e.target.value;
    const client = clients.find((cli) => cli._id === cId);
    const cName = client.name;
    setProjectData((prevData) => ({
      ...prevData,
      clientId: cId,
      clientName: cName,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const access = sessionStorage.getItem("access");
      await postRequest(
        "project",
        {
          Authorization: `Bearer ${access}`,
        },
        projectData
      );
      toast.success("Project Added Successfully", toastConfig);
      setProjectData({
        title: "",
        clientId: "",
        clientName: "",
        manager: "",
        description: "",
        startDate: "",
        deadLine: "",
        status: "",
      });
      handleClose();
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "black",
          color: "#FEFEFE",
          border: "1px solid #A9A9A9",
          p: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FEFEFE",
            "& fieldset": {
              borderColor: "#FEFEFE",
            },
            "&:hover fieldset": {
              borderColor: "#FEFEFE",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FEFEFE",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#FEFEFE",
          },
          "& .MuiIconButton-root": {
            color: "#FEFEFE",
          },
        }}
      >
        <ToastContainer />
        <Typography
          id="modal-modal-title"
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          component="h1"
          sx={{ mb: 2 }}
        >
          {projectId !== null ? "Update Project Record" : "Add Project Record"}
        </Typography>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item md={4}>
              <DeveloperModeIcon
                style={{
                  width: "100%",
                  height: "90%",
                  borderRadius: "8px",
                  color: "#E5622D",
                }}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-title">
                    Project Title
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-title"
                    type="text"
                    name="title"
                    label="Project Title"
                    value={projectData.title}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel id="client-select-label">Client Name</InputLabel>
                  <Select
                    labelId="client-select-label"
                    value={projectData.clientId}
                    onChange={handleSelectChange}
                    label="Client Name"
                  >
                    {clients.map((client) => (
                      <MenuItem key={client._id} value={client._id}>
                        {client.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-description">
                    Description
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-description"
                    type="text"
                    name="description"
                    label="Description"
                    value={projectData.description}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-manager">
                    Project Manager
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-manager"
                    type="text"
                    name="manager"
                    label="Project Manager"
                    value={projectData.manager}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-status">
                    Project Status
                  </InputLabel>
                  <Select
                    labelId="outlined-adornment-status-label"
                    id="outlined-adornment-status"
                    name="status"
                    label="Project Status"
                    value={projectData.status}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    {projectStatus.map((statusOpt) => (
                      <MenuItem key={statusOpt.value} value={statusOpt.value}>
                        {statusOpt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel
                    htmlFor="outlined-adornment-startDate"
                    shrink
                    sx={{
                      backgroundColor: "#000",
                      paddingX: "4px",
                      marginLeft: "-4px",
                    }}
                  >
                    Start Date
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-startDate"
                    type="date"
                    name="startDate"
                    label="Start Date"
                    value={projectData.startDate}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 1 }}>
                  <InputLabel
                    htmlFor="outlined-adornment-deadLine"
                    shrink
                    sx={{
                      backgroundColor: "#000",
                      paddingX: "4px",
                      marginLeft: "-4px",
                    }}
                  >
                    Dead Line
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-deadLine"
                    type="date"
                    name="deadLine"
                    label="Dead Line"
                    value={projectData.deadLine}
                    onChange={handleChange}
                    sx={{ backgroundColor: "transparent" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-around">
                {projectId !== null ? (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleUpdate}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={5}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSubmit}
                      sx={{
                        bgcolor: "#E5622D",
                        "&:hover": { bgcolor: "#D0521B" },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "#FF5722" }} />
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleClose}
                    disabled={loading}
                    sx={{
                      bgcolor: "#A9A9A9",
                      "&:hover": { bgcolor: "#E1E1E1" },
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddProject;
